/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import { css } from "@emotion/react";
import ListTitle from "./listTitle";

const wrapper = css`
  position: relative;
  margin-bottom: 30px;
  @media (max-width: 540px) {
    margin-bottom: 24px;
  }
`

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  line-height: 25px;
  margin: 9px 1px;
  font-weight: bold;
  @media (max-width: 540px) {
    text-align: center;
    font-size: 10px;
    line-height: 17px;
    margin: 15px 0px 0px;
    padding: 0px 10px;
  }
`
type Props = {
  title: string
  listNumber: string
  subTitle: string 
}
const Description: FC<Props> = (props) => {
  const {children} = props
  return (
    <div css={wrapper}>
      <ListTitle {...props} />
      <div css={text}>
        {children}
      </div>
    </div>
  )
}

export default Description;