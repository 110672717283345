/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  display: flex;
  border: 2px solid ${COLORS.SECONDARY};
  padding: 0px 65px;
  margin: 0px 30px;
  @media (max-width: 540px) {
    border: 2px solid ${COLORS.SECONDARY};
    padding: 2px 25px;
    margin: 0px 10px;
  }
`

const text = css`
  font-size: 40px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  color: ${COLORS.SECONDARY};
  @media (max-width: 540px) {
    font-size: 11px;
  }
`

const TextWithBorder: VFC<{title: string}> = (props) => {
  const {title} = props
  return (
    <div css={wrapper}>
      <div css={text}>{title}</div>
    </div>
  )
}

export default TextWithBorder;