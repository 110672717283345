/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import CardTitle from "../text/cardTitle";
import { BasicCard } from "./basicCard";
import Accordion from "../button/accordion";
import { questions } from "../../../constants/question";

const wrapper = css`
  padding: 30px 56px 25px;
  @media (max-width: 540px) {
    padding: 20px 10px 0px;
  }
`;

const QuestionCard: FC = () => {
  return (
    <BasicCard>
        <div css={wrapper}>
          <CardTitle title={'よくあるご質問'} subTitle={'FAQ'}/>
          {questions.map(question => 
            <div key={question.title}>
              <Accordion question={question}/>
            </div>
          )}
        </div>
    </BasicCard>
  )
};

export default QuestionCard;
