/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const wrapper = (title: string, size?: string) => css`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${size === 'S' ? '106px' : '145px'};
  height: ${size === 'S' ? '106px' : '145px'};
  background: ${title === '運動' ? '#5DB3F8' : '#fca518'};
  border-radius: 50%;
  margin: 0px 13px;
  @media (max-width: 540px) {
    width: ${size === 'S' ? '41px' : '55px'};
    height: ${size === 'S' ? '41px' : '55px'};
    margin: -4px 4px;
  }
`

const point = (size?: string) => css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 27px;
  font-weight: bold;
  color: ${COLORS.WHITE};
  margin-bottom: 5px;
  margin-left: 3px;
  @media (max-width: 540px) {
    font-size: ${size === 'S' ? '11px' : '10px'};
    margin-bottom: ${size === 'S' ? '0px' : '5px'};
    margin-left: ${size === 'S' ? '0px' : '3px'};
  }
`

const text = (size?: string) => css`
  font-size: 26px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  color: ${COLORS.WHITE};
  margin-bottom: -5px;
  @media (max-width: 540px) {
    font-size: ${size === 'S' ? '11px' : '10px'};
    margin-bottom: -3px;
  }
`

const CircleText: VFC<{title: string; number: string, size?: string}> = (props) => {
  const {title, number, size} = props
  return (
    <div css={wrapper(title, size)}>
      <div css={text(size)}>{title}</div>
      <div css={point(size)}>{number}</div>
    </div>
  )
}

export default CircleText;