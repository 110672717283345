/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";

const wrapper = css`
  width: 81%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  @media (max-width: 540px) {
    width: 88%;
  }
`

const inputLabel = css`
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  width: 100%;
  span {
    color: red;
  }
  @media (max-width: 540px) {
    font-size: 10px;
  }
`

const label = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  margin-top: 3px;
  @media (max-width: 540px) {
    margin-top: 3px;
    font-size: 11px;
  }
`

const radio = css`
  display: flex;
  align-items: center;
`

const radioWrapper = css`
  display: flex;
  margin-top: 8px;
  @media (max-width: 540px) {
    margin-top: 2px;
  }
`

type Props = {
  checked: boolean
  onChangeYes: (e: any) => void;
  onChangeNo: (e: any) => void;
}

const BasicRadio: VFC<Props> = (props) => {
  const {checked, onChangeYes,onChangeNo} = props
  return (
    <div css={wrapper}>
      <div css={inputLabel}>資料送付の希望<span> (必須)</span></div>
      <div css={radioWrapper}>
        <div css={radio}>
          <input type="radio" id="huey" name="document" checked={checked} onChange={onChangeYes}/>
          <label css={label}>希望する</label>
        </div>
        <div css={radio}>
          <input type="radio" id="huey" name="document" checked={!checked} onChange={onChangeNo}/>
          <label css={label}>希望しない</label>
        </div>
      </div>
    </div>
  )
}

export default BasicRadio;