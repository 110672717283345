/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { VFC } from "react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  display: flex;
  position: relative;
  justify-content: center;
`;

const imageWrapper = css`
  width: 1000px;
  height: 575px;
  position: relative;
  object-fit: cover;
  @media (max-width: 540px) {
    margin-left: 4px;
    width: 100%;
    height: 215px;
  }
`;

const title = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 38px;
  text-align: center;
  color: ${COLORS.WHITE};
  position: absolute;
  top: 124px;
  width: 100%;
  text-align: center;
  letter-spacing: 0px;
  @media (max-width: 540px) {
    font-size: 15px;
    top: 44px;
  }
`;

const list = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 38px;
  color: ${COLORS.WHITE};
  position: absolute;
  list-style-type: none;
  top: 173px;
  letter-spacing: 0px;
  line-height: 64px;
  margin-left: -25px;
  @media (max-width: 540px) {
    font-size: 14px;
    top: 64px;
    line-height: 24px;
    margin-left: -30px;
  }
`;

const BlackboardCard: VFC = () => {
  return (
    <div css={wrapper} >
      <img src="/images/blackboard.png" alt='arrow' css={imageWrapper}/>
      <div css={title}>GrowUPは成功体験を届けます</div>
      <ul css={list}>
        <li>・学力を伸ばしたい！</li>
        <li>・内申点を上げたい！</li>
        <li>・志望校に合格したい！</li>
      </ul>
    </div>
  )
};

export default BlackboardCard;

