/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  display: flex;
  align-items: center;
`

const imageWrapper = css`
  width: 35px;
  height: 35px;
  position: relative;
`;

const title = css`
  font-size: 19px;
  color: ${COLORS.PRIMARY};
  margin-left: 10px;
`;

type Props = {
    onClick: () =>void,
    text: string
}
const ButtonWithIcon: VFC<Props> = (props) => {
    const {onClick, text} = props
  return (
      <div css={wrapper} onClick={onClick}>
        <div css={imageWrapper}>
          <img src="/svg/arrow.svg" alt='arrow' width={35} height={35} />
        </div>
        <div css={title}>
          {text}
        </div>
      </div>
  )
}

export default ButtonWithIcon;