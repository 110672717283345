/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  display: flex;
  flex-direction: column;
  width: 990px;
  background: ${COLORS.WHITE};
  border-radius: 27px;
  position: relative;
  justify-content: center;
  margin-bottom: 30px;
  @media (max-width: 990px) {
    width: 700;
    border-radius: 10px;
  }
  @media (max-width: 540px) {
    width: 92.5%;
    border-radius: 10px;
  }
`;

export const BasicCard: FC = ({ children }) => {
  return (
    <div css={wrapper}>
      {children}
    </div>
  )
};
