/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useState } from "react";
import { COLORS } from "../../../constants/colors";

type Props = {
  item: {title: string; answer: string};
  isOpen?: boolean;
}

const contentText = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  text-align: left;
  padding: 0px 5px;
  @media (max-width: 540px) {
    margin-top: 5px;
    font-size: 10px;
  }
`;

const invisible = css`
  text-align: center;
  /* 閉じている状態 */
  border-top: 0 white solid;
  padding-top: 0;
  padding-bottom: 0;
  height: 0px;
  /* 閉じるアニメーション */
  transition:
    border-top     .3s ease-out,
    padding-top    .3s ease-out,
    height 5s linear,
    padding-bottom .3s ease-out;
`;

const visible = css`
/* 開いている状態 */
border-top: 1px white solid;
padding-top: 13px;
padding-bottom: 0px;
/* 開くアニメーション */
transition:
  border-top     .3s ease-out,
  padding-top    .3s ease-out,
  padding-bottom .3s ease-out;
  @media (max-width: 540px) {
  padding-top: 0px;
  padding-bottom: 3px;
  }
`;

const subText = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  padding: 8px 10px;
  color: white;
  @media (max-width: 540px) {
    font-size: 11px;
    padding: 8px 10px 8px 5px;
  }
`;

const subPlus = css`
  flex: 1;
  text-align: end;
  margin: auto;
  font-size: 17px;
  color: white;
`;
const subTitle = css`
display: flex;
background: ${COLORS.SECONDARY};
/* 閉じている状態 */
  line-height: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0px 7px;
  /* 閉じるアニメーション */
  transition:
    line-height .3s ease-out,
    opacity     .1s linear,
    visibility  .1s linear;
`;

const subTitleOpen = css`
display: flex;
background: ${COLORS.SECONDARY};
line-height: 1.5;
  opacity: 1;
  visibility: visible;
  padding: 0px 7px;
  /* 開くアニメーション */
  transition:
    line-height .3s ease-out,
    opacity     .1s linear .1s,
    visibility  .1s linear .1s;
`;

const content = css`
display: flex;
/* 閉じている状態 */
  line-height: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0px 7px;
  /* 閉じるアニメーション */
  transition:
    line-height .3s ease-out,
    opacity     .1s linear,
    visibility  .1s linear;
`;

const contentOpen = css`
  display: flex;
  line-height: 1.5;
  opacity: 1;
  visibility: visible;
  padding: 0px 7px;
  /* 開くアニメーション */
  transition:
    line-height .3s ease-out,
    opacity     .1s linear .1s,
    visibility  .1s linear .1s;
`;


export const SubAccordion: FC<Props> = ({isOpen, item}) => {
    const [subOpen, setSubOpen] = useState(false)

    return (
            <div css={isOpen ? visible : invisible}>
              <div css={isOpen ? subTitleOpen : subTitle} onClick={()=>setSubOpen(!subOpen)}>
                <div css={subText}>{item.title}</div>
                <div css={subPlus}>{subOpen ? 'ー' : '＋'}</div>
              </div>
               <div css={subOpen ? visible : invisible}>
                 <div css={subOpen ? contentOpen : content}>
                   <div css={contentText}>{item.answer}</div>
                 </div>
               </div>
             </div>
    )
};

export default SubAccordion;