/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";

const wrapper = css`
  align-items: center;
  width: 81%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  @media (max-width: 540px) {
    width: 88%;
  }
`

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  width: 100%;
  margin-top: 5px;
  @media (max-width: 540px) {
    font-size: 10px;
  }
`

const inputLabel = css`
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  width: 100%;
  @media (max-width: 540px) {
    font-size: 10px;
  }
`
type Props = {
  title: string
  name: string
  onChange: (e: any) => void;
  value: string;
}
const BasicTextAre: VFC<Props> = (props) => {
  const {title, name, onChange ,value} = props
  return (
    <div css={wrapper}>
      <label htmlFor={name} css={inputLabel}>{title}</label>
      <textarea id={name} rows={5} name={name} css={text} onChange={onChange} value={value}/>
    </div>
  )
}

export default BasicTextAre;