/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  position: relative;
`

const imageWrapper = css`
  width: 620px;
  height: 170px;
  position: relative;
  @media (max-width: 540px) {
    width: 225px;
    height: 61px;
  }
`;

const title = css`
  font-size: 38px;
  color: ${COLORS.SECONDARY};
  position: absolute;
  top: 9px;
  left: 30px;
  text-align: center;
  line-height: 60px;
  @media (max-width: 540px) {
    font-size: 11px;
    left: 25px;
    top: 6px;
    line-height: 18px;
  }
`;

const SpeechBubble: VFC = () => {
  return (
      <div css={wrapper}>
        <img css={imageWrapper} src="/svg/bubble.svg" alt='arrow'/>
        <div css={title}>
          ご相談は無料で承っております。<br />
          お気軽にご相談下さい！
        </div>
      </div>
  )
}

export default SpeechBubble;