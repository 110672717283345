/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  background: ${COLORS.WHITE};
  height: 140px;
  width: 315px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 540px) {
    height: 65px;
    width: 208px;
  }
  @media (min-width: 1500px) {
    font-size: 60px;
    height: 195px;
    width: 428px;
  }
`
const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 45px;
  color: ${COLORS.SECONDARY};
  @media (max-width: 540px) {
    font-size: 21px;
  }
  @media (min-width: 1500px) {
    font-size: 60px;
  }
`

const OnePointText: VFC<{title: string}> = ({title}) => {
  return (
    <div css={wrapper}>
      <div css={text}>
        {title}
      </div>
    </div>
  )
}

export default OnePointText;