/** @jsxImportSource @emotion/react */
import { FC, Fragment } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../constants/colors";

const menu = css`
    position: fixed;
    width: 100%;
    bottom: 0px;
    font-size: 0;
    z-index: 1;
  ul{
    display: flex;
    list-style: none;
    padding:0;
    margin:0;
    width:100%;
  }

  li {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding:0;
    margin:0;
    font-size: 27px;
    border-right: 1px solid #fff;
    color: #fff;
    text-align: center;
    display:block;
    width: 100%;
    padding:14px;
  }
  li:first-child{
   background: ${COLORS.PRIMARY};
  }

`;
type Props = {
    handleScrollForm: () => void
}

const SpMenu: FC<Props> = ({handleScrollForm}) => {
  const isSmartPhone = !!navigator.userAgent.match(/iPhone|Android.+Mobile/);
  return (
    isSmartPhone ? 
        <div css={menu} onClick={handleScrollForm}>
        <ul>
          <li>無料相談はこちら</li>
        </ul>
      </div>
      : <Fragment></Fragment>
  )
}

export default SpMenu;