/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useEffect, useRef, useState } from "react";
import CardTitle from "../text/cardTitle";
import { BasicCard } from "./basicCard";
import BasicInput from "../input/basicInput";
import BasicTextAre from "../input/basicTextares";
import emailjs from '@emailjs/browser';
import BasicRadio from "../radio/basicRadio";
import BasicSelect from "../input/basicSelect";

const wrapper = css`
  padding: 30px 56px 5px;
  @media (max-width: 540px) {
    padding: 20px 10px 0px;
  }
`;

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  margin-top: -34px;
  @media (max-width: 540px) {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 10px;
  }
`;

const formWrapper = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 37px;
  @media (max-width: 540px) {
    margin-top: 17px;
  }
`;

const button = (disabled: boolean) => css`
  background: transparent;
  border: 1px solid ${disabled ? '#C0C0C0' : '#FCA518'};
  color: ${disabled ? '#C0C0C0' : '#FCA518'};
  padding: 7px 68px;
  font-size: 22px;
  font-weight: bold;
  margin-top: 36px;
  margin-bottom: 40px;
  @media (max-width: 540px) {
    padding: 5px 28px;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom:20px;
  }
`;

const FormCard: FC = () => {
  const [name, setName] = useState('')
  const [furigana, setFurigana] = useState('')
  const [grade, setGrade] = useState('小学１年')
  const [parents, setParents] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [postCode, setPostCode] = useState('')
  const [address, setAddress] = useState('')
  const [content, setContent] = useState('')
  const [checked, setChecked] = useState(true)
  const [disabled, setDisabled] = useState(true)

  const form = useRef<any>(null);

  useEffect(() => {
    setDisabled(!name || !furigana || !grade || !parents || !phone || !email || !postCode || !address)
  },[name, furigana, grade, parents, phone, email, postCode, address])

  const sendEmail = () => {
    const templateParams = {
      name,
      furigana,
      grade,
      parents,
      phone,
      email,
      postCode,
      address,
      content,
      document: checked ? '希望する' : '希望しない'
    };

    emailjs.send('service_dh28ot4', 'template_12eyx4f', templateParams, 'user_tPcua7cCr8ROEUoMI32YD')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
    alert('メールを送信しました。')
  };
  return (
    <BasicCard>
        <div css={wrapper}>
          <CardTitle title={'ご相談・資料請求（無料）'} subTitle={'APPLY'}/>
          <div css={text}>お電話でのお問い合わせも承っております。<br />お電話でのお問い合わせの方は<a href="tel:090-8473-4153">090-8473-4153</a>にお掛けください。</div>
          <div css={content}>
            <form ref={form} css={formWrapper} onSubmit={sendEmail} method="POST">
              <BasicInput type={'text'} title={'お名前'} name={'name'} value={name} onChange={(e) => {setName(e.target.value);}}/>
              <BasicInput type={'text'} title={'ふりがな'} name={'furigana'} value={furigana} onChange={(e) => {setFurigana(e.target.value);}}/>
              <BasicSelect type={'text'} title={'学年'} name={'grade'} value={grade} onChange={(e) => {setGrade(e.target.value);}}/>
              <BasicInput type={'text'} title={'保護者の氏名'} name={'parents'} value={parents} onChange={(e) => {setParents(e.target.value);}}/>
              <BasicInput type={'tel'} title={'携帯電話番号'} name={'phone'} value={phone} onChange={(e) => {setPhone(e.target.value);}}/>
              <BasicInput type={'email'} title={'メールアドレス'} name={'email'} value={email} onChange={(e) => {setEmail(e.target.value);}}/>
              <BasicInput type={'text'} title={'郵便番号'} name={'postCode'} value={postCode} onChange={(e) => {setPostCode(e.target.value);}}/>
              <BasicInput type={'text'} title={'住所'} name={'address'} value={address} onChange={(e) => {setAddress(e.target.value);}}/>
              <BasicRadio checked={checked} onChangeYes={() => setChecked(true)} onChangeNo={() => setChecked(false)}/>
              <BasicTextAre title={'具体的なご相談内容'} name={'content'} value={content} onChange={(e) => setContent(e.target.value)}/>
              <button css={button(disabled)} type="submit" disabled={disabled}>
                送信する
              </button>
            </form>
          </div>
        </div>
    </BasicCard>
  )
};

export default FormCard;
