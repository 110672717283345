/** @jsxImportSource @emotion/react */
import { VFC } from "react";
import { css } from "@emotion/react";
import HeaderText from "../atoms/text/headerText";
import ButtonWithIcon from "../atoms/button/buttonWithIcon";
import { BasicButton } from "../atoms/button/basicButton";
import HamburgerMenu from "../atoms/button/hamburgerMenu";
import { COLORS } from "../../constants/colors";
import Modal from "./modal";

const wrapper = css`
  height: 108px;
  display: flex;
  top: 0; 
  align-items: center;
  padding: 0px 18px;
  background: ${COLORS.WHITE};
  z-index: 2;
  position: sticky;
  justify-content: space-between;
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  @media (max-width: 540px) {
    height: 70px;
    padding: 0px 13px;
  }
`

const arrowButtonWrapper = css`
  margin-right: 11px;
  cursor: pointer;
  @media (max-width: 1200px) {
    display: none;
  }
`

const arrowQuestionButtonWrapper = css`
  cursor: pointer;
  @media (max-width: 1200px) {
    display: none;
  }
`

const buttonWrapper = css`
  margin: 0px 35px 0px 17px;
  cursor: pointer;
  @media (max-width: 1050px) {
    display: none;
  }
`
type Props = {
  handleScrollPoint: () => void
  handleScrollMethod: () => void
  handleScrollPrice: () => void
  handleScrollGuarantee: () => void
  handleScrollEntry: () => void
  handleScrollForm: () => void
  handleScrollCompany: () => void
  handleFAQCompany: () => void
  onClickMenu: any
  isModal: boolean
}

export const Header: VFC<Props> = ({handleScrollPoint,
  handleScrollMethod,
  handleScrollPrice,
  handleScrollGuarantee,
  handleScrollEntry,
  handleFAQCompany,
  handleScrollForm,
  handleScrollCompany,
  onClickMenu,
  isModal
}) =>{
  return (
    <header css={wrapper}>
      <HeaderText />
      <div css={arrowButtonWrapper}>
        <ButtonWithIcon text={'ご入会までの流れ'} onClick={handleScrollEntry}/>
      </div>
      <div css={arrowQuestionButtonWrapper}>
        <ButtonWithIcon text={'よくあるご質問'} onClick={handleFAQCompany}/>
      </div>
      <div css={buttonWrapper}>
        <BasicButton text={'ご相談・資料請求（無料）'} onClick={handleScrollForm}/>
      </div>
      <HamburgerMenu onClick={onClickMenu} isModal={isModal}/>
      {isModal && <Modal handleScrollPoint={handleScrollPoint}
          handleScrollMethod={handleScrollMethod}
          handleScrollPrice={handleScrollPrice}
          handleScrollGuarantee={handleScrollGuarantee}
          handleScrollEntry={handleScrollEntry}
          handleScrollForm={handleScrollForm}
          handleScrollCompany={handleScrollCompany}
          handleFAQCompany={handleFAQCompany}
        />}
    </header>
  )
}