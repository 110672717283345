/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useState } from "react";
import { COLORS } from "../../../constants/colors";
import SubAccordion from "./subAccordion";

type Props = {
  question: {
    title: string,
    list: {title: string; answer: string}[]
  };
}

const wrapper = css`
  margin-bottom: 32px;
  @media (max-width: 540px) {
    margin-bottom: 15px;
    margin-top: 15px;
  }
`;

const title = css`
  display: flex;
`;

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  padding: 7px 10px;
  @media (max-width: 540px) {
    margin-top: 0px;
    margin-left: 0px;
    font-size: 12px;
    padding: 2px 10px;
  }
`;

const line = css`
    width: 10px;
    background: ${COLORS.SECONDARY50};
    @media (max-width: 540px) {
      width: 5px;
    }
`;

const plus = css`
  flex: 1;
  text-align: end;
  margin: auto;
  font-size: 17px;
`;

const invisible = css`
/* 閉じている状態 */
border-top: 0 white solid;
padding-top: 0;
padding-bottom: -10px;
/* 閉じるアニメーション */
transition:
  border-top     .3s ease-out,
  padding-top    .3s ease-out,
  padding-bottom .3s ease-out,
`;

const visible = css`
/* 開いている状態 */
border-top: 1px white solid;
padding-top: 13px;
padding-bottom: 0px;
/* 開くアニメーション */
transition:
  border-top     .3s ease-out,
  padding-top    .3s ease-out,
  padding-bottom .3s ease-out,
`;

export const Accordion: FC<Props> = ({question}) => {
    const [open, setOpen] = useState(false)

    return (
          <div css={wrapper}>
            <div css={title} onClick={()=>setOpen(!open)}>
              <div css={line}/>
              <div css={text}>{question.title}</div>
              <div css={plus}>{open ? 'ー' : '＋'}</div>
            </div>
            <div css={open ? visible : invisible}>
              {question.list.map((item) => 
                <SubAccordion isOpen={open} item={item} key={item.title}/>
              )}
            </div>
          </div>
    )
};

export default Accordion;