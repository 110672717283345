/** @jsxImportSource @emotion/react */
import { VFC } from "react";
import { css } from "@emotion/react";

const modal = () => css`
  width: 100%;
  height: 350px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 108px;
  left: 0;
  z-index: 1;
  @media (max-width: 540px) {
    height: 270px;
    top: 70px;
    justify-content: flex-end;
  }
`;

const spWrapper = css`
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0px;
  height: 350px;
  @media (max-width: 540px) {
    width: 90%;
    height: 200px;
  }
`

const content = css`
  display: flex;
  flex-direction: column;
  height: 220px;
  @media (max-width: 540px) {
    height: 170px;
  }
`
const tel = css`
  animation: SlideUp 2s;
  @keyframes SlideUp {
    0% {
      opacity: 0;/*初期状態では透明に*/
      transform: translateY(144px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  font-size: 40px;
  padding-bottom: 20px;
  text-decoration: none;
  font-weight: bold;
  @media (max-width: 540px) {
    font-size: 25px;
  padding-bottom: 40px;
  }
`

const linkText1 = css`
  animation: SlideIn 0.5s;
  @keyframes SlideIn {
    0% {
      opacity: 0;/*初期状態では透明に*/
      transform: translateX(164px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  font-size: 20px;
  line-height: 57px;
  letter-spacing: 0.08em;
  margin: 0px 12px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: black;
  @media (max-width: 540px) {
    font-size: 11px;
    line-height: 40px;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
  }
`
const linkText2 = css`
  animation: SlideIn 1.0s;
  @keyframes SlideIn {
    0% {
      opacity: 0;/*初期状態では透明に*/
      transform: translateX(264px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  font-size: 20px;
  line-height: 57px;
  letter-spacing: 0.08em;
  margin: 0px 12px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: black;
  @media (max-width: 540px) {
    font-size: 11px;
    line-height: 40px;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
  }
`
const linkText3 = css`
  animation: SlideIn 1.5s;
  @keyframes SlideIn {
    0% {
      opacity: 0;/*初期状態では透明に*/
      transform: translateX(164px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  font-size: 20px;
  line-height: 57px;
  letter-spacing: 0.08em;
  margin: 0px 12px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: black;
  @media (max-width: 540px) {
    font-size: 11px;
    line-height: 40px;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
  }
`
const linkText4 = css`
  animation: SlideIn 2.0s;
  @keyframes SlideIn {
    0% {
      opacity: 0;/*初期状態では透明に*/
      transform: translateX(164px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  font-size: 20px;
  line-height: 57px;
  letter-spacing: 0.08em;
  margin: 0px 12px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: black;
  @media (max-width: 540px) {
    font-size: 11px;
    line-height: 40px;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
  }
`


type Props = {
  handleScrollPoint: () => void
  handleScrollMethod: () => void
  handleScrollPrice: () => void
  handleScrollGuarantee: () => void
  handleScrollEntry: () => void
  handleScrollForm: () => void
  handleScrollCompany: () => void
  handleFAQCompany: () => void
}


const Modal: VFC<Props> = ({
  handleScrollPoint,
  handleScrollMethod,
  handleScrollPrice,
  handleScrollGuarantee,
  handleScrollEntry,
  handleScrollForm,
  handleScrollCompany,
  handleFAQCompany,
})  => {
    
    return (
      <div css={modal}>
        <div css={spWrapper}>
          <div css={content}>
            <div css={linkText1} onClick={handleScrollPoint}>
              GrowUPのこだわり
            </div>
            {/* <div css={linkText2} onClick={handleScrollPrice}>
              授業料について
            </div> */}
            {/* <Link href={"url"} passHref>
              <a css={linkText}>
                お客様の声
              </a>
            </Link> */}
            <div css={linkText1} onClick={handleScrollMethod}>
              結果で応えるGrowUPメソッド
            </div>
            <div css={linkText3} onClick={handleScrollEntry}>
              ご入会までの流れ
            </div>
            <div css={linkText3} onClick={handleScrollCompany}>
              運営会社
            </div>
          </div>
          <div css={content}>
            <div css={linkText2} onClick={handleScrollGuarantee}>
              安心の保証制度
            </div>
            <div css={linkText3} onClick={handleFAQCompany}>
              よくあるご質問
            </div>
            <div css={linkText4} onClick={handleScrollForm}>
              お問い合わせ・資料請求
            </div>
          </div>
        </div>
        <a href="tel:090-8473-4153" css={tel}>090-8473-4153</a>
      </div>
    )
}

export default Modal;