/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import { COLORS } from "../../../constants/colors";
import ApproachText from "../text/approachText";
import CardTitle from "../text/cardTitle";
import { BasicCard } from "./basicCard";
import ResultCard from "./resultCard";
import DocumentRequest from "../../organisms/DocumentRequest";
import BasicList from "../list/basicList";

const wrapper = css`
  padding: 30px 56px 35px;
  @media (max-width: 540px) {
    padding: 20px 10px 35px;
  }
`;

const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const label = css`
  border-radius: 18px;
  background: #fca518;
  text-align: center;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: ${COLORS.WHITE};
  padding: 5px 18px;
  @media (max-width: 540px) {
    padding: 2px 20px;
    font-size: 11px;
    border-radius: 58px;
    margin: 10px 0px;
  }
`;

const appeal = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 46px;
  margin: 10px 0px 3px 5px;
  @media (max-width: 540px) {
    font-size: 16px;
    font-weight: 600;
    margin: -3px 0px 3px 5px;
  }

  span {
    -webkit-text-stroke: 2px #000;
    color: #fff;
    font-size: 52px;
    font-weight: 400;
    @media (max-width: 540px) {
      font-size: 21px;
      -webkit-text-stroke: 0.5px #000;
    }
  }
`;

const grades1 = {
  name: '中学1年生のMくん',
  image: '/images/test1.png',
  result: [
    {subject: '国語', rank: '35位UP！'},
    {subject: '社会', rank: '76位UP！'},
    {subject: '数学', rank: '64位UP！'},
    {subject: '理科', rank: '24位キープ'},
    {subject: '英語', rank: '25位UP！'},
  ],
  text: 'わずか3ヶ月で合計順位',
  totalRank: '50位UP!!',
}

const grades2 = {
  name: '中学2年生のKさん',
  image: '/images/test2.png',
  result: [
    {subject: '国語', rank: '17位UP！'},
    {subject: '社会', rank: '57位UP！'},
    {subject: '数学', rank: '30位UP！'},
    {subject: '理科', rank: '20位UP！'},
    {subject: '英語', rank: '32位UP！'},
  ],
  text: 'わずか2ヶ月で合計順位',
  totalRank: '31位UP!!',
}
type Props = {
  handleScrollForm: () => void
}
const MethodCard: FC<Props> = ({handleScrollForm}) => {
  return (
    <BasicCard>
        <div css={wrapper}>
          <CardTitle title={'結果で応えるGrowUPメソッド'} subTitle={'GrowUP Method'}/>
          <div css={content}>
            <div css={label}>勉強に関する全てをGrowUPに</div>
            <div css={appeal}><span>まるっ</span>とおまかせ！</div>
            <ResultCard grades={grades1}/>
            <ResultCard grades={grades2}/>
            <ApproachText />
            <BasicList />
            <DocumentRequest handleScrollForm={handleScrollForm}/>
          </div>
        </div>
    </BasicCard>
  )
};

export default MethodCard;
