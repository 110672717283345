/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import { COLORS } from "../../../constants/colors";
import CardTitle from "../text/cardTitle";
import StepDescription from "../text/stepDescription";
import { BasicCard } from "./basicCard";

const wrapper = css`
  padding: 30px 50px 35px;
  @media (max-width: 540px) {
    padding: 20px 10px 0px;
  }
`;

const content = css`
    padding: 10px 2px;
`;

const textWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const label = css`
  border-radius: 18px;
  background: #fca518;
  text-align: center;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: ${COLORS.WHITE};
  padding: 12px 38px;
  margin-top: -10px;
  margin-bottom: 20px;
  line-height: 26px;
  @media (max-width: 540px) {
    padding: 2px 20px;
    font-size: 11px;
    border-radius: 58px;
    margin: 10px 0px;
  }
`;
const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  line-height: 25px;
  margin: 9px 1px;
  font-weight: bold;
  text-align: center;
  line-height: 26px;
  @media (max-width: 540px) {
    font-size: 10px;
    line-height: 13px;
    line-height: 18px;
    margin: 0px 1px;
    transform: scale(0.9);
    width: 110%;
  }
`
const note = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 10px;
  line-height: 13px;
  margin: 9px 1px;
  font-weight: bold;
  line-height: 16px;
  @media (max-width: 540px) {
    font-size: 10px;
    line-height: 13px;
    line-height: 18px;
    margin: 0px 1px;
    transform: scale(0.9);
  }
`
const imageWrapper = css`
width: 100%;
height: 270px;
margin-top: -15px;
position: relative;
@media (max-width: 540px) {
  width: 100%;
  height: 100px;
}
`;

export const EntryCardPc: FC = () => {
  return (
    <BasicCard>
      <div css={wrapper}>
          <CardTitle title={'ご入会までの流れ'} subTitle={'ENTRY'}/>
          <div css={textWrapper}>
            <div css={label}>安心して始めてもらうために</div>
            <div css={text}>
              GrowUPは、他の家庭教師会社にはない特長を持つ家庭教師会社です。<br/>
              私たちは、生徒様とご家庭様のご希望とご要望をしっかりと達成するために<br/>
              ご入会までに1時間ほどご面談の機会をお願いしております。<br/>
              ご面談を通してお子様の状況を把握させていただき、生徒様にとって最善は何なのか<br/>
              GrowUPと共に考えさせてください。<br/>
            </div>
            <div css={note}>
              ※ご面談を通し家庭教師よりも教室型の方がご家庭様・生徒様にとって良いと判断した場合<br/>
              大変恐縮ですがご入会をお断りさせていただく場合もあります。ご了承ください。
            </div>
          </div>
        <div css={content}>
          <StepDescription title={'ご面談（対面orテレビ電話）'} listNumber={'01'} subTitle={'STEP'}>
            GrowUPの教育プランナーが、お子さまの学習状況やご家庭様のご要望やお困り事をヒアリングいたします。<br/>
            ご面談の際には、現在のお子さまの学習状況が分かるテスト用紙、通知表、模試結果などがある場合はご準備をお願いいたします。<br/>
            その他GrowUPに対するご質問やご要望がございましたら、こちらのご面談の際に合わせてお伝えください。<br/>
          </StepDescription>
          <StepDescription title={'指導カリキュラムのご共有'} listNumber={'02'} subTitle={'STEP'}>
            ご面談でお伺いさせていただきました情報を基に、地域の学校情報、受験情報に精通した教育プランナーが<br/>
            各ご家庭様に合わせて指導内容と指導回数をまとめた、生徒様専用のオーダーメイドカリキュラムを作成しご共有いたします。
          </StepDescription>
          <StepDescription title={'ご検討＆ご相談'} listNumber={'03'} subTitle={'STEP'}>
            ステップ2でご共有差し上げましたカリキュラムをご確認いただき、<br/>
            ご家庭様の方でしっかりとご納得していただけた場合、ご入会のお手続きに進ませていただきます。<br/>
            もし指導カリキュラムへご要望やご指摘がございましたら、柔軟に対応することが可能ですので<br/>
            一番ご家庭様がご安心していただける状態でご入会のお手続きを進めさせていただければと思います。
          </StepDescription>
          <StepDescription title={'ご入会のお手続き'} listNumber={'04'} subTitle={'STEP'}>
            ご入金の手続きをさせていただき、弊社教育プランナーと担当教師を踏まえたグループLINEを作成させていただき<br/>
            随時、ご家庭様と生徒様の学習状況をご共有できる環境を構築させていただき、指導をスタートいたします。
          </StepDescription>
          <img css={imageWrapper} src="/images/entry.png" alt='arrow'/>
        </div>
      </div>
    </BasicCard>
  )
};
export const EntryCardSp: FC = () => {
  return (
    <BasicCard>
      <div css={wrapper}>
          <CardTitle title={'ご入会までの流れ'} subTitle={'ENTRY'}/>
          <div css={textWrapper}>
            <div css={label}>安心して始めてもらうために</div>
            <div css={text}>
              GrowUPは、他の家庭教師会社にはない特長を持つ家庭教師会社です。<br/>
              私たちは、生徒様とご家庭様のご希望とご要望をしっかりと達成するために<br/>
              ご入会までに1時間ほどご面談の機会をお願いしております。<br/>
              ご面談を通してお子様の状況を把握させていただき、<br/>生徒様にとって最善は何なのか
              GrowUPと共に考えさせてください。<br/>
            </div>
            <div css={note}>
              ※ご面談を通し家庭教師よりも教室型の方がご家庭様・生徒様にとって良いと判断した場合
              大変恐縮ですがご入会をお断りさせていただく場合もあります。ご了承ください。
            </div>
          </div>
        <div css={content}>
          <StepDescription title={'ご面談（対面orテレビ電話）'} listNumber={'01'} subTitle={'STEP'}>
          GrowUPの教育プランナーが、お子さまの学習状況やご家庭様のご要望やお困り事をヒアリングいたします。ご面談の際には、現在のお子さまの学習状況が分かるテスト用紙、通知表、模試結果などがある場合はご準備をお願いいたします。その他GrowUPに対するご質問やご要望がございましたら、こちらのご面談の際に合わせてお伝えください。
          </StepDescription>
          <StepDescription title={'指導カリキュラムのご共有'} listNumber={'02'} subTitle={'STEP'}>
          ご面談でお伺いさせていただきました情報を元に、地域の学校情報・受験情報に精通した教育プランナーが、各ご家庭様に合わせて指導内容と指導回数をまとめた生徒様専用のオーダーメイドカリキュラムを作成しご共有いたします。
          </StepDescription>
          <StepDescription title={'ご検討＆ご相談'} listNumber={'03'} subTitle={'STEP'}>
          ステップ2でご共有差し上げましたカリキュラムをご確認いただき、ご家庭様の方でしっかりとご納得していただけた場合、ご入会のお手続きに進ませていただきます。
もし指導カリキュラムへご要望やご指摘がございましたら、柔軟に対応することが可能ですので、一番ご家庭様がご安心していただける状態でご入会のお手続きを進めさせていただければと思います。
          </StepDescription>
          <StepDescription title={'ご入会のお手続き'} listNumber={'04'} subTitle={'STEP'}>
          ご入金の手続きをさせていただき、弊社教育プランナーと担当教師を踏まえたグループLINEを作成し、随時ご家庭様と生徒様の学習状況をご共有できる環境を構築させていただき、指導をスタートいたします。
          </StepDescription>
          <img css={imageWrapper} src="/images/entry.png" alt='arrow'/>
        </div>
      </div>
    </BasicCard>
  )
};

