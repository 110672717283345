/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import { css } from "@emotion/react";
import DescriptionWithLine from "../text/descriptionWithLine";

const wrapper = css`
  position: relative;
  margin-top: 33px;
  padding: 15px;
  @media (max-width: 540px) {
    padding: 15px 6px;
  }
`

const BasicList: FC = () => {
  const isSmartPhone = !!navigator.userAgent.match(/iPhone|Android.+Mobile/);
  return (
    <div css={wrapper}>
      <DescriptionWithLine title={'100人いれば100通りのカリキュラム'}>
        生徒様の苦手と得意、部活などのスケジュールも考慮した「完全オーダーメイド型」のカリキュラムですので、一人ひとりの
        ペースで着実に理解していくことが可能です。{!isSmartPhone && <br/>}
        また、家庭教師とは別に専任の教育プランナーが生徒様のモチベーションを引き上げ、成績アップまでサポートいたします。
      </DescriptionWithLine>
      <DescriptionWithLine title={'教材の購入は不要'}>
        GrowUPでは、基本的に新規での教材の利用を推奨しておりません。理由は多くの教材に手を出し過ぎてしまうと、かえって
        学力の向上が遅れるためです。漢検や英検など、資格を獲得したい場合の教材の使用は推奨しますが、基本的な学力UPにおい
        ては、学校で使用している教材とGrowUP式の指導方法で確実に学力が向上します。
      </DescriptionWithLine>
      <DescriptionWithLine title={'学力の向上と共に、内申点も改善！'}>
        学力が上がれば内申点も上がると思ってらっしゃる保護者様は多いのではないでしょうか？{!isSmartPhone && <br/>}
        しかし、実際は学力が上がったとしても内申点が変わらないケースが多々あります。特に小学校においては、学力が上がったと
        しても内申点に目に見えるほどの変化が現れることはほとんどありません。GrowUPでは学力向上はもちろん、内申点を上げる
        方法を熟知しています。内申点でお悩みを抱えていらっしゃる生徒様もお任せください。
      </DescriptionWithLine>
    </div>
  )
}

export default BasicList;