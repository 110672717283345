/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const iconWrapper = css`
  position: relative;
  width: 56px;
  height: 55px;
  @media (max-width: 540px) {
    width: 31px;
    height: 31px;
  }
`

const leftTop = css`
  background: ${COLORS.PRIMARY};
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0px;
  @media (max-width: 540px) {
    width: 14px;
    height: 14px;
  }
`

const leftBottom = css`
  background: ${COLORS.SECONDARY};
  width: 26px;
  height: 26px;
  position: absolute;
  bottom: 0px;
  @media (max-width: 540px) {
    width: 14px;
    height: 14px;
  }
`

const rightBottom = css`
  background: ${COLORS.PRIMARY};
  width: 26px;
  height: 26px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  @media (max-width: 540px) {
    width: 14px;
    height: 14px;
  }
`

const BasicIcon: VFC = () => {
  return (
      <div css={iconWrapper}>
        <div css={leftTop}></div>
        <div css={leftBottom}></div>
        <div css={rightBottom}></div>
      </div>
  )
}

export default BasicIcon;