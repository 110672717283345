/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import { COLORS } from "../../../constants/colors";
import CardTitle from "../text/cardTitle";
import { BasicCard } from "./basicCard";
import CircleCard from "./circleCard";

const wrapper = css`
  padding: 30px 56px 5px;
  @media (max-width: 540px) {
    padding: 20px 10px 35px;
  }
`;

const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const label = css`
  border-radius: 18px;
  background: #fca518;
  text-align: center;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: ${COLORS.WHITE};
  padding: 5px 70px;
  margin-top: 10px;
  @media (max-width: 540px) {
    border-radius: 30px;
    font-size: 11px;
    padding: 2px 30px;
    margin-top: 5px;
  }
`;

const title = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 53px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
  span {
    position: absolute;
    top: -1px;
    right: -17px;
    font-size: 20px;
  }
  @media (max-width: 540px) {
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  span {
    position: absolute;
    top: -1px;
    right: -10px;
    font-size: 10px;
    transform:scale(0.8);
  }
`;

const note = css`
  margin-top: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 15px;
  position: relative;
  line-height: 25px;
  margin-left: 20px;
  @media (max-width: 540px) {
    font-size: 10px;
    line-height: 11px;
    font-weight: 400;
    margin-left: 0px;
    transform:scale(0.8);
    margin-top: 15px;
    width: 115%;
  }
`;

const anxietyWrapper = css`
  margin-top: 18px;
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 540px) {
    margin-top: 20px;
  }
`;

const anxietyTitle = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 26px;
  position: relative;
  margin-bottom: -10px;
  @media (max-width: 540px) {
    font-size: 13px;
  }
`;

const anxietyText = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 26px;
  position: relative;
  @media (max-width: 540px) {
    font-size: 10px;
  }
`;

const line = css`
  border-top: 2px solid #707070;
  width: 85%;
  margin-top: 12px;
  margin-bottom: 30px;
  @media (max-width: 540px) {
    border-top: 3px solid #707070;
    width: 95%;
    margin-top: 10px;
    margin-bottom: 03px;
  }
`;

const anxiety = css`
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
`;

const anxietyList = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const imageWrapper = css`
  width: 43px;
  height: 43px;
  position: relative;
  margin-right: 6px;
  margin-left: -6px;
  @media (max-width: 540px) {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    margin-top: -2px;
  }
`;

const GuaranteeCard: FC = () => {
  return (
    <BasicCard>
        <div css={wrapper}>
          <CardTitle title={'安心の保証制度'} subTitle={'Guarantee system'}/>
          <div css={content}>
            <div css={label}>自信があるからできる</div>
            <div css={title}>全額返金保証制度<span>※</span></div>
            <CircleCard />
            <div css={note}>
              ※入会時に契約したコースの初回のご利用から30日間。<br />
              ※返金の手続きは担当教育プランナー同席の上書面で行うものとし、電話、メールなどの手段による手続きには応じかねます。<br />
              ※体育家庭教師GrowUPでかかったレッスン費用に関してはご対応しかねます。<br />
              ※30日間全額返金保証を利用すると再入会できません。
            </div>
            <div css={anxietyWrapper}>
              <div css={anxietyTitle}>こんな理由でも大丈夫</div>
              <hr css={line}></hr>
              <div css={anxietyList}>
                <div css={anxiety}>
                  <img css={imageWrapper} src="/images/check.png" alt='arrow'/>
                  <div css={anxietyText}>部活やその他習い事の都合でどうしても合わない</div>
                </div>
                <div css={anxiety}>
                  <img css={imageWrapper} src="/images/check.png" alt='arrow'/>
                  <div css={anxietyText}>やっぱり自分の子どもには合わないかも</div>
                </div>
              </div>
              <hr css={line}></hr>
            </div>
          </div>
        </div>
    </BasicCard>
  )
};

export default GuaranteeCard;
