/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import type { VFC } from "react";

const wrapper = css`
  position: relative;
`;

const imageWrapper = css`
  width: 345px;
  height: 345px;
  position: relative;
  @media (max-width: 540px) {
    width: 145px;
    height: 145px;
  }
`;

const day = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 75px;
  margin-top: 4px;
  position: absolute;
  top: 125px;
  left: 60px;
  color: #A47C77;
  @media (max-width: 540px) {
    font-size: 30px;
    left: 25px;
    top: 50px;
  }
`;

const title = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 26px;
  position: absolute;
  top: 95px;
  left: 110px;
  @media (max-width: 540px) {
    font-size: 10px;
    left: 45px;
    top: 35px;
  }
`;

const CircleCard: VFC = () => {
  return (
    <div css={wrapper} >
      <img css={imageWrapper} src='/images/circle.png' alt='arrow'/>
      <div css={title}>コース料金</div>
      <div css={day}>30日間</div>
    </div>
  )
};

export default CircleCard;