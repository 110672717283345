/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  margin-bottom: 50px;
  @media (max-width: 540px) {
    margin-bottom: 5px;
  }
`

const main = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 30px;
  line-height: 25px;
  margin: 6px 1px;
  font-weight: bold;
  @media (max-width: 540px) {
    font-size: 14px;
    margin: 0px 1px;
  }
`

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  line-height: 25px;
  margin: 9px 1px;
  font-weight: bold;
  @media (max-width: 540px) {
    font-size: 10px;
    line-height: 16px;
    margin: 0px 1px;
  }
`

const line = css`
  width: 391px;
  height: 4px;
  background: linear-gradient(90deg, ${COLORS.SECONDARY}, ${COLORS.WHITE});
  @media (max-width: 540px) {
    height: 4px;
    width: 100%;
    margin-top: -4px;
  }
`

type Props = {
  title: string
}
const DescriptionWithLine: FC<Props> = (props) => {
  const {title, children} = props
  return (
    <div css={wrapper}>
      <div css={main}>{title}</div>
      <div css={line}></div>
      <div css={text}>
        {children}
      </div>
    </div>
  )
}

export default DescriptionWithLine;