/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import CardTitle from "../text/cardTitle";
import Description from "../text/description";
import { BasicCard } from "./basicCard";
import BlackboardCard from "./blackboardCard";

const wrapper = css`
  margin-top: 40px;
  @media (max-width: 540px) {
    margin-top: 20px;
  }
`;

const content = css`
  padding: 36px 56px;
  @media (max-width: 540px) {
    padding: 19px 11px 0px;
  }
`;

const PointCard: FC = () => {
  const isSmartPhone = !!navigator.userAgent.match(/iPhone|Android.+Mobile/);
  return (
    <BasicCard>
      <div css={wrapper}>
        <BlackboardCard />
        <div css={content}>
          <CardTitle title={'POINT'} subTitle={'こだわり'}/>
          <Description title={'プロ家庭教師のマンツーマン指導'} listNumber={'01'} subTitle={'こだわり'}>
            皆様は家庭教師と聞いてどんなイメージを持たれますか？{<br/>}
            「先生がマンツーマンでお家で教えてくれる」「子供の苦手な科目を集中して教えてくれる」{!isSmartPhone && <br/>}
            様々なご意見があると思いますが、私たちは業界にいるからこそ分かる”曖昧さ”を知っています。{<br/>}
            教師の登録数が多いといっても、内情を見たらそこら辺にいる大学生が先生をしている。{!isSmartPhone && <br/>}
            学歴はあるけど、人に教えた経験のない先生だから子供の学力が上がらない。{!isSmartPhone && <br/>}
            上記は家庭教師業界ではいたって当たり前にあることです。{<br/>}
            GrowUPの家庭教師は、経験や合格実績、人間性など厳しい採用基準で選ばれており{!isSmartPhone && <br/>}
            「一定の学力がある」ことは大前提「教える」ことに対して本気の教師しか在籍しておりません。{!isSmartPhone && <br/>}
            生徒様の目標を達成するために、厳選されたプロ家庭教師が生徒様と二人三脚でサポートさせていただきます。
          </Description>
          <Description title={'目的に合わせた独自カリキュラム'} listNumber={'02'} subTitle={'こだわり'}>
            本サイトをご覧いただいていると言うことは、勉強に対して関心のある方しかいないと思います。{!isSmartPhone && <br/>}
            しかし、一概に「勉強」といっても、生徒様によって目的・目標は様々です。{<br />}
            「学校の内申点を上げたい！」「学力を向上させたい！」{<br />}
            「苦手科目を克服させたい！」「志望校に受かりたい！」{<br />}
            よくあるご希望として上記４つを挙げさせていただきましたが、それぞれアプローチの方法は異なります。{!isSmartPhone && <br/>}
            GrowUPでは、学校の内申点の付け方の仕組み〜各生徒様に合った学力向上方法を熟知しておりますので{!isSmartPhone && <br/>}
            保護者様とお子様を踏まえてヒアリングさせていただき、目的・目標に合わせたお子様だけの独自カリキュラムに沿って{!isSmartPhone && <br/>}
            指導を行っていきます。{<br />}
          </Description>
          <Description title={'学校教育では補えない部分をアプローチ'} listNumber={'03'} subTitle={'こだわり'}>
            学校という場所は、勉学以上の価値のある部分を培ってくれる素晴らしい環境です。{!isSmartPhone && <br/>}
            各お子様によって学校での役回りは違います。明るくムードメーカーな子、真面目でクラスの行事をまとめてくれる{<br />}
            しっかり者、ちょっとおうちゃくで元気な子、おとなしくて少し内気な子。{<br />}
            それぞれが学校生活を通して学力では測れない人間として大切な能力が養われ、個性が育っていきます。{!isSmartPhone && <br/>}
            しかし、学問を教えるにあたり集団であるが故に理解が追いついていないまま進んでいってしまう現状、{!isSmartPhone && <br/>}
            テストの点数は取れたけど、答えの理由が分からないまま暗記で覚えてしまっていた。。。{!isSmartPhone && <br/>}
            など、学校教育において勉学はまだまだテコ入れが必要な状況です。{<br />}
            GrowUPでは家庭教師というスタイルのもと、生徒様一人ひとりにとって最適な学習環境を提供し学力の向上を実現いたします。{<br />}
          </Description>
        </div>
      </div>
    </BasicCard>
  )
};

export default PointCard;
