/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";

const wrapper = css`
  display: flex;
  align-items: center;
  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const subtext = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 11px;
  color: ${COLORS.PRIMARY};
  margin-bottom: -7px;
  @media (max-width: 540px) {
    font-size: 10px;
    transform:scale(0.7);
  }
`

const number = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 36px;
  color: ${COLORS.PRIMARY};
  font-weight: bold;
  @media (max-width: 540px) {
    font-size: 25px;
    text-align: center;
  }
`

const text = css`
  font-size: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  margin-bottom: -6px;
  margin-left: 10px;
  @media (max-width: 540px) {
    font-size: 18px;
    margin-top: -5px;
    margin-left: 0px;
  }
`

type Props = {
  title: string
  listNumber: string
  subTitle: string
}
const ListTitle: VFC<Props> = (props) => {
  const {title, listNumber, subTitle} = props
  return (
    <div css={wrapper}>
      <div>
        <div css={subtext}>{subTitle}</div>
        <div css={number}>{listNumber}</div>
      </div>
      <div css={text}>{title}</div>
    </div>
  )
}

export default ListTitle;