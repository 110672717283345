/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";
import CardIcon from "../icon/cardIcon";

const wrapper = css`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 49px;
  @media (max-width: 540px) {
    align-items: start;
    margin-bottom: -2px;
  }
`
const textWrapper = css`
  margin-left: 11px;
  margin-bottom: 1px;
`
const subtext = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  color: ${COLORS.SECONDARY50};
  margin-bottom: -8px;
  @media (max-width: 540px) {
    font-size: 10px;
    margin-left: -4px;
    margin-bottom: -8px;
  }
`

const text = css`
  font-size: 34px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  @media (max-width: 540px) {
    font-size: 19px;
    margin-left: -4px;
    margin-top: 1px;
  }
`

const CardTitle: VFC<{title: string; subTitle: string}> = (props) => {
  const {title, subTitle} = props
  return (
    <div css={wrapper}>
      <CardIcon />
      <div css={textWrapper}>
        <div css={subtext}>{subTitle}</div>
        <div css={text}>{title}</div>
      </div>
    </div>
  )
}

export default CardTitle;