/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";

const wrapper = css`
  display: flex;
  align-items: center;
`


const imageWrapper = css`
  width: 47px;
  height: 41px;
  position: relative;
  @media (max-width: 540px) {
    width: 40px;
    height: 35px;
  }
`;
const hamburger = css`
  position: relative;
  cursor: pointer;
`;

const hamburgerLine1 = (isModal: boolean) => css`
  position: absolute;
  width: 47px;
  height: 5px;
  border-radius: 10px;
  right: 0;
  background-color: #707070;
  transition: all 0.3s; 
  ${!isModal ?
    'top: 1px;'
    :
    'transform: rotate(-45deg); top: 15px;'
  }
  @media (max-width: 540px) {
    width: 40px;
    height: 5px;
    ${!isModal ?
      'top: 3px;'
      :
      'transform: rotate(-45deg); top: 17px;'
    }
  }
`;
const hamburgerLine2 = (isModal: boolean) => css`
  position: absolute;
  width: 47px;
  height: 5px;
  border-radius: 10px;
  right: 0;
  background-color: #707070;
  transition: all 0.3s;
  top: 18px;
  @media (max-width: 540px) {
    width: 40px;
    height: 5px;
  }
  ${isModal && 'opacity: 0;'}
`;
const hamburgerLine3 = (isModal: boolean) => css`
  position: absolute;
  width: 47px;
  height: 5px;
  border-radius: 10px;
  right: 0;
  background-color: #707070;
  transition: all 0.3s;
  ${!isModal ?
    'top: 36px;'
    :
    'transform: rotate(45deg); top: 15px;'
  }
  @media (max-width: 540px) {
    width: 40px;
    height: 5px;
    ${!isModal ?
      'top: 33px;'
      :
      'transform: rotate(45deg); top: 17px;'
    }
  }
`;

type Props = {
    onClick: () =>void,
    isModal: boolean,
}
const HamburgerMenu: VFC<Props> = (props) => {
    const {onClick, isModal} = props
  return (
      <div css={wrapper} onClick={onClick}>
        <div css={imageWrapper}>
          <div>
            <div css={hamburger}>
              <span css={hamburgerLine1(isModal)}></span>
              <span css={hamburgerLine2(isModal)}></span>
              <span css={hamburgerLine3(isModal)}></span>
            </div>
            {/* <div css="black-bg" id="js-black-bg"></div> */}
          </div>
        </div>
      </div>
  )
}

export default HamburgerMenu;