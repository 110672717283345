/** @jsxImportSource @emotion/react */
import { VFC } from "react";
import { css } from "@emotion/react";

const wrapper = css`
  width: 100%;
  height: 307px;
  background: #FCA518;
  display: flex;
  align-items: center;
  @media (max-width: 1150px) {
    flex-direction: column;
    height: auto;
  }
  @media (max-width: 540px) {
    flex-direction: column;
    height: auto;
  }
`

const spWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
`

const pcWrapper = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 1150px) {
    justify-content: center;
  }
`

const content = css`
  display: flex;
  flex-direction: column;
  margin-top: 94px;
  margin-left: 23px;
  height: 160px;
  justify-content: flex-start;
  @media (max-width: 1150px) {
    margin-top: 30px;
    margin-bottom: 0px;
    justify-content: flex-start;
  }
  @media (max-width: 540px) {
    margin-top: 44px;
    margin-bottom: 45px;
  }
`

const linkText = css`
  font-size: 17px;
  line-height: 37px;
  letter-spacing: 0.08em;
  color: #FFFFFF;
  margin: 0px 12px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: white;
  @media (max-width: 540px) {
    font-size: 11px;
    line-height: 34px;
    font-weight: bold;
    margin: 0px 10px 0px 0px;
  }
`

const imageWrapper = css`
  width: 498px;
  height: 64px;
  position: relative;
  margin-top: 40px;
  margin-left: 88px;
  @media (max-width: 1150px) {
    width: 338px;
  }
  @media (max-width: 540px) {
    width: 280px;
    height: 52px;
    margin-left: 0px;
    margin-top: 21px;
  }
`;

type Props = {
  handleScrollPoint: () => void
  handleScrollMethod: () => void
  handleScrollPrice: () => void
  handleScrollGuarantee: () => void
  handleScrollEntry: () => void
  handleScrollForm: () => void
  handleScrollCompany: () => void
  handleFAQCompany: () => void
}

const Footer: VFC<Props> = ({
  handleScrollPoint,
  handleScrollMethod,
  handleScrollPrice,
  handleScrollGuarantee,
  handleScrollEntry,
  handleScrollForm,
  handleScrollCompany,
  handleFAQCompany,
})  => {
  const isSmartPhone = !!navigator.userAgent.match(/iPhone|Android.+Mobile/);
  return (
    <footer css={wrapper}>
      <img src="/images/footerIcon.png" alt='arrow' css={imageWrapper}/>
      {isSmartPhone ? 
        <div css={spWrapper}>
          <div css={content}>
            <div css={linkText} onClick={handleScrollPoint}>
              GrowUPのこだわり
            </div>
            <div css={linkText} onClick={handleScrollMethod}>
              結果で応えるGrowUPメソッド
            </div>
            <div css={linkText} onClick={handleScrollEntry}>
              ご入会までの流れ
            </div>
            <div css={linkText} onClick={handleScrollCompany}>
              運営会社
            </div>
            {/* <div css={linkText} onClick={handleScrollPrice}>
              授業料について
            </div> */}
            {/* <Link href={"url"} passHref>
              <a css={linkText}>
                お客様の声
              </a>
            </Link> */}
            
          </div>
          <div css={content}>
            <div css={linkText} onClick={handleScrollGuarantee}>
              安心の保証制度
            </div>
            <div css={linkText} onClick={handleFAQCompany}>
              よくあるご質問
            </div>
            <div css={linkText} onClick={handleScrollForm}>
              お問い合わせ・資料請求
            </div>
          </div>
        </div>
        :
          <div css={pcWrapper}>
            <div css={content}>
              <div css={linkText} onClick={handleScrollPoint}>
                GrowUPのこだわり
              </div>
              <div css={linkText} onClick={handleFAQCompany}>
              よくあるご質問
            </div>
              {/* <Link css={linkText}>
                <a css={linkText}>
                  お客様の声
                </a>
              </Link> */}
              <div css={linkText} onClick={handleScrollCompany}>
                運営会社
              </div>
            </div>
            <div css={content}>
              {/* <div css={linkText} onClick={handleScrollPrice}>
                授業料について
              </div> */}
              <div css={linkText} onClick={handleScrollGuarantee}>
                安心の保証制度
              </div>
              <div css={linkText} onClick={handleScrollEntry}>
                ご入会までの流れ
              </div>
            </div>
            <div css={content}>
            <div css={linkText} onClick={handleScrollMethod}>
                結果で応えるGrowUPメソッド
              </div>
              <div css={linkText} onClick={handleScrollForm}>
                お問い合わせ・資料請求
              </div>
            </div>
          </div>
      }
    </footer>
  )
}

export default Footer