/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";

const wrapper = css`
  align-items: center;
  width: 81%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  @media (max-width: 540px) {
    margin-bottom: 10px;
    width: 88%;
  }
`

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  width: 100%;
  margin-top: 5px;
  font-size: 16px;
  @media (max-width: 540px) {
    font-size: 10px;
    margin-top: 0px;
  }
`

const inputLabel = css`
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  width: 100%;
  span {
    color: red;
  }
  @media (max-width: 540px) {
    font-size: 10px;
  }
`
type Props = {
  title: string
  name: string
  onChange: (e: any) => void;
  value: string;
  type?: string;
}
const BasicInput: VFC<Props> = (props) => {
  const {title, name, onChange, value, type} = props
  return (
    <div css={wrapper}>
      <label htmlFor={name} css={inputLabel}>{title}<span> (必須)</span></label>
      <input onChange={onChange} id={name} name={name} css={text} value={value} type={type}/>
    </div>
  )
}

export default BasicInput;