/** @jsxImportSource @emotion/react */
import BasicLayout from './components/templates/basicLayout'
import {css} from '@emotion/react'
import MethodCard from './components/atoms/card/methodCard';
import GuaranteeCard from './components/atoms/card/guaranteeCard';
import PriceCard from './components/atoms/card/priceCard';
import FormCard from './components/atoms/card/formCard';
import { EntryCardPc, EntryCardSp } from './components/atoms/card/entryCard';
import { Fragment, useRef, useState } from 'react';
import TopImage from './components/organisms/topImage';
import PointCard from './components/atoms/card/pointCard';
import QuestionCard from './components/atoms/card/questionCard';
import CompanyCard from './components/atoms/card/companyCard';

const content = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const cardWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const entryCardPcWrapper = css`
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 540px) {
    display: none;
  }
`;
const entryCardSpWrapper = css`
  display: none;
  @media (max-width: 540px) {
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)
  const scrollPointRef = useRef<any>(null);

  const handleScrollPoint = () => {
    setIsOpen(false)
    scrollPointRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollMethodRef = useRef<any>(null);
  const handleScrollMethod = () => {
    setIsOpen(false)
    scrollMethodRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollPriceRef = useRef<any>(null);
  const handleScrollPrice = () => {
    setIsOpen(false)
    scrollPriceRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollGuaranteeRef = useRef<any>(null);
  const handleScrollGuarantee = () => {
    setIsOpen(false)
    scrollGuaranteeRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollEntryRef = useRef<any>(null);
  const handleScrollEntry = () => {
    setIsOpen(false)
    scrollEntryRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollFormRef = useRef<any>(null);
  const handleScrollForm = () => {
    setIsOpen(false)
    scrollFormRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollCompanyRef = useRef<any>(null);
  const handleScrollCompany = () => {
    setIsOpen(false)
    scrollCompanyRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }
  const scrollFAQRef = useRef<any>(null);
  const handleFAQCompany = () => {
    setIsOpen(false)
    scrollFAQRef?.current?.scrollIntoView({behavior: "smooth", block: "start"});
  }
  const options: AddEventListenerOptions = {
    passive: false,
    capture: false
}


  const onClickMenu = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
    
  }
  
  return (
    <BasicLayout
      handleScrollPoint={handleScrollPoint}
      handleScrollMethod={handleScrollMethod}
      handleScrollPrice={handleScrollPrice}
      handleScrollGuarantee={handleScrollGuarantee}
      handleScrollEntry={handleScrollEntry}
      handleScrollForm={handleScrollForm}
      onClickMenu={onClickMenu}
      onClickCompany={handleScrollCompany}
      handleFAQCompany={handleFAQCompany}
      isModal={isOpen}
    >
      <Fragment>
        <TopImage/>
        <div css={content}>
          <div ref={scrollPointRef} css={cardWrapper} >
            <PointCard />
          </div>
          <div ref={scrollGuaranteeRef} css={cardWrapper}>
            <GuaranteeCard/>
          </div>
          <div ref={scrollMethodRef} css={cardWrapper}>
            <MethodCard handleScrollForm={handleScrollForm}/>
          </div>
          {/* <div ref={scrollPriceRef} css={cardWrapper}>
            <PriceCard/>
          </div> */}
          <div ref={scrollFAQRef} css={cardWrapper}>
            <QuestionCard/>
          </div>
          <div ref={scrollEntryRef} css={entryCardSpWrapper}>
            <EntryCardSp/>
          </div>
          <div ref={scrollEntryRef} css={entryCardPcWrapper}>
            <EntryCardPc/>
          </div>
          <div ref={scrollFormRef} css={cardWrapper}>
            <FormCard/>
          </div>
          <div ref={scrollCompanyRef} css={cardWrapper}>
            <CompanyCard/>
          </div>
        </div>
      </Fragment>
    </BasicLayout>
  )
}
Home.getInitialProps = ({ res, err }: {
  res: any;
  err: any;
}) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}
export default Home
