/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { COLORS } from "../../constants/colors";
import { FC } from "react";
import { Header } from "../organisms/header";
import Footer from "../../components/organisms/footer";
import SpMenu from "../../components/organisms/spMenu";

const wrapper = css`
  display: flex;
  width: 100%;
  background: ${COLORS.SECONDARY100};
  justify-content: center;
  @media (max-width: 540px) {
    justify-content: flex-start;
  }
`;

const childrenWrapper = css`
  
`;

const content = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${COLORS.SECONDARY100}
`;

type Props = {
  handleScrollPoint: () => void
  handleScrollMethod: () => void
  handleScrollPrice: () => void
  handleScrollGuarantee: () => void
  handleScrollEntry: () => void
  handleScrollForm: () => void
  onClickCompany: () => void
  handleFAQCompany: () => void
  isModal: boolean
  onClickMenu: any
}

const BasicLayout: FC<Props> = ({
  handleScrollPoint,
  handleScrollMethod,
  handleScrollPrice,
  handleScrollGuarantee,
  handleScrollEntry,
  handleScrollForm,
  onClickCompany,
  handleFAQCompany,
  onClickMenu,
  isModal,
  children
}) => {
  return (
    <div css={wrapper}>
      <div css={content} >
        <Header
          handleScrollPoint={handleScrollPoint}
          handleScrollMethod={handleScrollMethod}
          handleScrollPrice={handleScrollPrice}
          handleScrollGuarantee={handleScrollGuarantee}
          handleScrollEntry={handleScrollEntry}
          handleScrollForm={handleScrollForm}
          handleFAQCompany={handleFAQCompany}
          handleScrollCompany={onClickCompany}
          onClickMenu={onClickMenu} isModal={isModal}/>
        <div css={childrenWrapper}>
          {children}
        </div>
        <Footer
          handleScrollPoint={handleScrollPoint}
          handleScrollMethod={handleScrollMethod}
          handleScrollPrice={handleScrollPrice}
          handleScrollGuarantee={handleScrollGuarantee}
          handleScrollEntry={handleScrollEntry}
          handleScrollForm={handleScrollForm}
          handleFAQCompany={handleFAQCompany}
          handleScrollCompany={onClickCompany}
        />
      </div>
      <SpMenu handleScrollForm={handleScrollForm}/>
    </div>
  )
};

export default BasicLayout
