/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";
import TextWithBorder from "./textWithBorder";

const wrapper = css`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 92%;
  border: 3px solid ${COLORS.SECONDARY};
  margin-top: 32px;
  @media (max-width: 540px) {
    border: 3px solid ${COLORS.SECONDARY};
    margin-top: 10px;
  }
`

const title = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 26px;
  font-weight: bold;
  color: ${COLORS.SECONDARY};
  position: absolute;
  top: -20px;
  padding: 0px 8px;
  background: ${COLORS.WHITE};
  @media (max-width: 540px) {
    font-size: 10px;
    top: -10px;
  }
`

const main = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 60px;
  margin: 3px 0px 3px 5px;
  -webkit-text-stroke: 2px #000;
  color: ${COLORS.WHITE};
  margin-top: 20px;
  @media (max-width: 540px) {
    font-size: 19px;
    -webkit-text-stroke: 0.8px #000;
    margin-top: 5px;
  }
`

const sub = css`
  display: flex;
  justify-content: center;
  margin-top: 38px;
  margin-bottom: 26px;
  @media (max-width: 540px) {
    margin-top: 0px;
    margin-bottom:10px;
  }
`

const plus = css`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 18px;
  margin: 0 5px;

  ::before {
    display: block;
    content: '';
    background-color: ${COLORS.SECONDARY};
    border-radius: 10px;
    position: absolute;
    width: 18px;
    height: 5px;
    top: 7px;
    left: 0;
    width: 2px;
    height: 18px;
    top: 0;
    left: 7px;
  }
  ::after {
    display: block;
    content: '';
    background-color: ${COLORS.SECONDARY};
    border-radius: 10px;
    position: absolute;
    width: 41px;
    height: 2px;
    top: 20px;
    left: 0;
    @media (max-width: 540px) {
      height: 3px;
      top: 6px;
      left: 10px;
      border-radius: 0px;
      width: 15px;
    }
  }

  ::before {
    width: 2px;
    height: 40px;
    top: 0;
    left: 19px;
    @media (max-width: 540px) {
      width: 3px;
      left: 16px;
      border-radius: 0px;
      height: 15px;
    }
  }
`

const ApproachText: VFC = () => {

  return (
    <div css={wrapper}>
      <div css={title}>全方位アプローチ</div>
      <div css={main}>本当の学力を身に付ける</div>
      <div css={plus} />
      <div css={sub}>
        <TextWithBorder title={'知 識'} />
        <TextWithBorder title={'習 慣'} />
      </div>
    </div>
  )
}

export default ApproachText;