/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import OnePointText from "../atoms/text/onePointText";
import { COLORS } from "../../constants/colors";

const wrapper = css`
  display: flex;
  flex-direction: column;
  position: relative;
`

const imageWrapper = css`
  width: 100%;
  height: 750px;
  position: relative;
  object-fit: cover;
  @media (min-width: 1500px) {
    height: 900px;
  }
  @media (max-width: 540px) {
    height: 590px;
  }
`;

const h1 = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 60px;
  text-align: left;
  color: #fff;
  position: absolute;
  top: 101px;
  left: 37px;
  line-height: 102px;
  @media (min-width: 1500px) {
    font-size: 80px;
    line-height: 132px;
  }
  @media (max-width: 1150px) {
    font-size: 40px;
    top:79px;
    left: 40px;
    line-height: 77px;
  }
  @media (max-width: 540px) {
    font-size: 28px;
    top: 9px;
    left: 10px;
    line-height: 47px;
  }
`;

const onePoint1 = css`
  position: absolute;
  top: 151px;
  right: 95px;
  @media (max-width: 1150px) {
    top: 250px;
    right: 84px;
  }
  @media (max-width: 540px) {
    top: 200px;
    right: 34px;
  }
  @media (min-width: 1500px) {
    top: 160px;
    right: 134px;
  }
`;

const onePoint2 = css`
  position: absolute;
  top: 304px;
  right: 56px;
  @media (max-width: 1150px) {
    top: 400px;
    right: 56px;
  }
  @media (max-width: 540px) {
    top: 275px;
    right: 23px;
  }
  @media (min-width: 1500px) {
    top: 360px;
    right: 84px;
  }
`;

const onePoint3 = css`
  position: absolute;
  top: 456px;
  right: 19px;
  @media (max-width: 1150px) {
    top: 552px;
    right: 14px;
  }
  @media (max-width: 540px) {
    top: 352px;
    right: 14px;
  }
  @media (min-width: 1500px) {
    top: 570px;
    right: 34px;
  }
`;

const boxWrapper = css`
  display: flex;
  border-top: solid 5px #77B1FF;
  border-bottom: solid 5px #77B1FF;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 1;
  
  @media (min-width: 1150px) {
    position: absolute;
    top: 415px;
    left: 72px;
    border: solid 3px #77B1FF;
    padding: 2px;
  }
  @media (min-width: 540px) {
    position: absolute;
    top: 415px;
    left: 72px;
    border: solid 3px #77B1FF;
    padding: 2px;
    border-top: solid 3px #77B1FF;
    border-bottom: solid 3px #77B1FF;
  }
  @media (min-width: 1500px) {
    top: 515px;
    left: 82px;
  }
`;

const box = css`
  background: rgba(119, 177, 255, 0.37);
  border-top: 3px solid rgba(255, 255, 255, 0.77);
  border-bottom: 3px solid rgba(255, 255, 255, 0.77);
  height: 100%;
  padding: 0px 61px;
  @media (max-width: 1150px) {
    border-top: 2px solid rgba(255, 255, 255, 1);
    border-bottom: 2px solid rgba(255, 255, 255, 1);
    background: rgba(119, 177, 255, 1);
    width: 100%;
    text-align: center;
    padding: 15px 25px;
  }
  @media (max-width: 540px) {
    border-top: 4px solid rgba(255, 255, 255, 1);
    border-bottom: 4px solid rgba(255, 255, 255, 1);
    background: rgba(119, 177, 255, 1);
    width: 100%;
    text-align: center;
    padding: 0px 0px;
  }
  @media (min-width: 1500px) {
    padding: 16px 85px;
  }
`;

const boxText = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 45px;
  text-align: left;
  color: #fff;
  text-align: center;
  line-height: 75px;
  @media (max-width: 1150px) {
    font-size: 38px;
    line-height: 52px;
  }
  @media (max-width: 540px) {
    font-size: 30px;
    line-height: 52px;
  }
  @media (min-width: 1500px) {
    font-size: 60px;
    line-height: 102px;
  }
`;

const bottomLineWrapper = css`
  position: absolute;
  bottom: -76px;
  width: 100%;
  z-index: 1;
  @media (max-width: 540px) {
    bottom: -35px;
  }
`;

const bottomLine = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const line = css`
  height: 31px;
  width: 100%;
  background: ${COLORS.SECONDARY};
  @media (max-width: 540px) {
    height: 20px;
  }
`;

const triangle = css`
  border-right: 120px solid transparent;
  border-bottom: 46px solid ${COLORS.SECONDARY};
  border-left: 120px solid transparent;
  transform: rotate(180deg);
  width: 10px;
  @media (max-width: 540px) {
    border-right: 30px solid transparent;
    border-bottom: 15px solid ${COLORS.SECONDARY};
    border-left: 30px solid transparent;
  }
`;


const TopImage: VFC = () => {
  const isSmartPhone = !!navigator.userAgent.match(/iPhone|Android.+Mobile/);
  return (
    <div css={wrapper}>
      <img css={imageWrapper} src={isSmartPhone ? "/images/topSp.png" : "/images/top.png"} alt='top' />
      <div css={h1}>お子様・保護者様の「夢」<br />GrowUPが全力で叶えます!!</div>
      <div css={onePoint1}>
        <OnePointText title={'「がんばる！」'}/>
      </div>
      <div css={onePoint2}>
        <OnePointText title={'「できる！」'}/>
      </div>
      <div css={onePoint3}>
        <OnePointText title={'「楽しい！」'}/>
      </div>
      <div css={boxWrapper}>
        <div css={box}>
          <div css={boxText}>
          自信があるからできる<br />30日間全額返金保証制度
          </div>
        </div>
      </div>
      <div css={bottomLineWrapper}>
        <div css={bottomLine}>
          <div css={line} />
          <div css={triangle} />
        </div>
      </div>
    </div>
  )
}

export default TopImage;