/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import SpeechBubble from "../atoms/text/speechBubble";
import DocumentRequestButton from "../atoms/button/documentRequestButton";

const wrapper = css`
  margin-top: -35px;
  display: flex;
  @media (max-width: 540px) {
    margin-top: -3px;
  }
`;

const svgWrapper = css`
  width: 150px;
  height: 93px;
  position: relative;
  margin-top: -10px;
  margin-left: -50px;
  object-fit: cover;
  @media (max-width: 540px) {
    width: 52px;
    height: 33px;
    margin-top: -5px;
    margin-left: -30px;
  }
`;

const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const buttonWrapper = css`
  margin-top: 18px;
  margin-left: -45px;
  @media (max-width: 540px) {
    margin-top: 8px;
    margin-left: -15px;
  }
`

const imageWrapper = css`
  width: 240px;
  height: 305px;
  position: relative;
  margin-top: 55px;
  margin-left: 13px;
  object-fit: cover;
  @media (max-width: 540px) {
    width: 85px;
    height: 110px;
    margin-top: 25px;
    margin-left: 5px;
  }
`;

type Props = {
  handleScrollForm: () => void
}
const DocumentRequest: VFC<Props> = ({handleScrollForm}) => {
  return (
    <div css={wrapper}>
      <div css={content}>
        <SpeechBubble />
        <img css={svgWrapper} src="/svg/downArrow.svg" alt='arrow'/>
        <div css={buttonWrapper}>
          <DocumentRequestButton text={'無料相談＆資料請求はこちら'} onClick={handleScrollForm}/>
        </div>
      </div>
      <img css={imageWrapper} src="/images/teacher.png" alt='arrow'/>
    </div>
  )
}

export default DocumentRequest;