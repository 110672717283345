/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import type { ButtonHTMLAttributes, FC } from "react";
import { COLORS } from "../../../constants/colors";

type colorType = "black";

type Props = {
  colorType?: colorType;
  width?: string;
  type?: "submit" | "button";
  text?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const button = () => css`
    position: relative;
    padding: 12px 18px;
    border-radius: 12px;
    border: none;
    margin: 5px;
    background-color: ${COLORS.SECONDARY};
    &:hover {
      opacity: 0.6;
    }
    @media (max-width: 540px) {
      padding: 8px 8px;
    }
`

const span = css`
  font-size: 37px;
  color: white;
  letter-spacing: 1px;
  font-weight: 100;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  @media (max-width: 540px) {
    font-size: 13px;
  }
`;

export const DocumentRequestButton: FC<Props> = ({ type, onClick, text }) => (
  <button type={type} css={button} onClick={onClick}>
    <span css={span}>{text}</span>
  </button>
);

export default DocumentRequestButton;
