export const questions = [
    {
        title: '料金・システム等に関して',
        list: [
            {
                title: '平日の遅い時間帯や日程でも指導を受けられますか？',
                answer: 'ご希望の時間に合わせて教師を選択します。お子様のご都合に合わせて指導時間や曜日をお選びください。平日21時以降の遅い時間帯や休日の指導も大丈夫です。もちろん途中で変更もできます。'
            },
            {
                title: '授業料はどれくらいかかりますか？',
                answer: '平均で月額3万円~5万円といった形になります。各ご家庭様のご要望内容、生徒様の学年で変わるため、詳しい料金はお手数をおかけいたしますがお電話、お問い合わせにてご相談下さい'
            },
            {
                title: '授業料以外にかかる費用はありますか？',
                answer: '入会金の1万円が必要になります。また、模試を受けられる場合、模試費用に関しましてはお客様負担になります。その他教材費や管理費などは基本的にかかりません。'
            },
            {
                title: '料金はどのように支払いますか？',
                answer: '毎月20日に翌月のコマ数をお伺いさせていただき、月末にご請求書の方を発行させていただきます。ご請求書に関しましてはメール、LINE、郵送などお客様のご要望に合わせて発行させていただきます。'
            },
        ]
    },
    {
        title: '生徒さんの状況に関して',
        list: [
            {
                title: '苦手な科目の対策はできますか？',
                answer: '苦手科目の克服こそ、1対1の指導が効果を発揮します。GrowUPはお子様の一人一人の学習状況や性格に合わせて学習プランを立てるので、無理なく自分のペースで勉強に取り組めます。もちろん進捗状況を見て、プランの立て直しも行うのでご安心ください。'
            },
            {
                title: '入試情報や志望校の決定など、進路相談にものって欲しい',
                answer: 'GrowUPなら教育プランナーが責任を持ってご相談を承ります。教育プランナーは学習アドバイザーとしての経験を豊富に積んだGrowUPのエキスパートです。入試情報の提供や志望校選びなどの進路相談は教育プランナーが責任を持って承ります。'
            },
            {
                title: '学習習慣をつけることは可能ですか？',
                answer: 'GrowUPには学習習慣がつくノウハウがあります。学習習慣をつけるために必要なのは、お子様のやる気と学習の計画性です。GrowUPではお子様の一人一人に最適な学習カリキュラムを作成しご提案します。お子様のやる気を引き出し自分で計画的に学習を進めていけるように導きます。'
            },
            {
                title: '塾や予備校の授業の補習はできますか？',
                answer: 'GrowUPなら塾や予備校の補習対策も万全です。GrowUPでは塾の授業に合わせて塾のテキストを使った指導を受けることができます。お子様の学習状況に応じて、必要があれば問題の質や量を調整しながら指導を行います。他の塾とGrowUPを併用することで学習効果がいっそう高まります。'
            },
        ]
    },
    {
        title: '先生・教師に関して',
        list: [
            {
                title: '教師を交代してもらうことはできますか？',
                answer: 'GrowUPなら教師の交代が何回でも無料で行えます。教師との相性が合わなかったり、指導内容に不満があったりする場合は、ご希望により教師を交代することができます。交代に費用はかかりません。納得がいくまで教師を選べます。'
            },
            {
                title: 'どんな先生が教えてくれるのですか？',
                answer: 'GrowUPでは、基礎学力があることは大前提、過去に指導者としての経験がある方、もしくは今後「教員」として働くことを望んでいる方しか在籍していません。つまり教えることに対して熱意のある先生しか在籍していないことがGrowUPの強みです。'
            },
            {
                title: '先生の性別や年齢を選ぶことは可能ですか？',
                answer: '可能です。生徒さんのご希望に合った教師をご選抜させていただきます。'
            },
            {
                title: '先生に伝えにくい伝えにくいことがある場合どうすれば良いでしょうか？	',
                answer: 'お気軽にGrowUpまでご相談ください。本部のアドバイザーが間に入り、改善への橋渡し役になります。'
            },
        ]
    },
    {
        title: '30日間全額保証制度に関して',
        list: [
            {
                title: 'いつから30日間でしょうか？',
                answer: 'GrowUPでは安心して利用していただけるように、対面授業を開始してから30日間という期間を設けさせていただいております。'
            },
            {
                title: '30日間全額保証ってどうやって受け取るの？',
                answer: '全額保証の手続きは担当の教育プランナー同席の上で応じさせていただいております。※テスト期間などの短期間のみのご利用を前提として受講していただくことはご遠慮いただいております。'
            },
        ]
    },
]