/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";

const wrapper = css`
  align-items: center;
  width: 81%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  @media (max-width: 540px) {
    margin-bottom: 10px;
    width: 88%;
  }
`

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  width: 101%;
  margin-top: 5px;
  font-size: 16px;
  height: 34px;
  @media (max-width: 540px) {
    font-size: 10px;
    margin-top: 0px;
    height: 24px;
    width: 103%;
  }
`

const inputLabel = css`
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  width: 100%;
  span {
    color: red;
  }
  @media (max-width: 540px) {
    font-size: 10px;
  }
`
type Props = {
  title: string
  name: string
  onChange: (e: any) => void;
  value: string;
  type?: string;
}
const BasicSelect: VFC<Props> = (props) => {
  const {title, name, onChange, value} = props
  return (
    <div css={wrapper}>
      <label htmlFor={name} css={inputLabel}>{title}<span> (必須)</span></label>
      <select onChange={onChange} id={name} name={name} css={text} value={value}>
        <option value="小学１年">小学１年</option>
        <option value="小学２年">小学２年</option>
        <option value="小学３年">小学３年</option>
        <option value="小学４年">小学４年</option>
        <option value="小学５年">小学５年</option>
        <option value="小学６年">小学６年</option>
        <option value="中学１年">中学１年</option>
        <option value="中学２年">中学２年</option>
        <option value="中学３年">中学３年</option>
        <option value="高校１年">高校１年</option>
        <option value="高校２年">高校２年</option>
        <option value="高校３年">高校３年</option>
      </select>
    </div>
  )
}

export default BasicSelect;