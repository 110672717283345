/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC } from "react";
import { COLORS } from "../../../constants/colors";
import CardTitle from "../text/cardTitle";
import { BasicCard } from "./basicCard";

const wrapper = css`
  padding: 30px 56px 35px;
  @media (max-width: 540px) {
    padding: 20px 10px 0px;
  }
`;

const content = css`
    margin-top: -30px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 540px) {
      margin-top: 20px;
    }
`;

const label = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  @media (max-width: 540px) {
    font-size: 10px;
  }
`;

const text = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 17px;
  text-align: left;
  margin: 5px 30px 25px;
  @media (max-width: 540px) {
    font-size: 13px;
    margin: 5px 15px 15px;
  }
`;

const data = css`
  margin: 10px;
  width: 47%;
  @media (max-width: 540px) {
    width: 100%;
    margin: 0px 10px;
  }
`;
const line = css`
  width: 5px;
  height: 25px;
  background: ${COLORS.SECONDARY50};
  margin-right: 10px;
  @media (max-width: 540px) {
    width: 4px;
    height: 15px;
    margin-right: 5px;
  }
`;
const title = css`
  display: flex;
  align-items: center;
`;

const CompanyCard: FC = () => {
  return (
    <BasicCard>
      <div css={wrapper}>
          <CardTitle title={'運営会社'} subTitle={'ENTRY'}/>
        <div css={content}>
          <div css={data}>
             <div css={title}>
              <div css={line}/>
              <div>
                <div css={label}>会社名</div>
              </div>
            </div>
            <div css={text}>株式会社KOKUKO</div>
          </div>
          <div css={data}>
             <div css={title}>
              <div css={line}/>
              <div>
                <div css={label}>会社 URL</div>
              </div>
            </div>
                <a href={'https://kokuko-inc.com/'} css={text}>https://kokuko-inc.com/</a>
          </div>
          <div css={data}>
             <div css={title}>
              <div css={line}/>
              <div>
                <div css={label}>住所</div>
              </div>
            </div>
                <div css={text}>〒483-8279 愛知県江南市古知野町宮裏２２２　MK宮前ビル３F</div>
          </div>
          <div css={data}>
             <div css={title}>
              <div css={line}/>
              <div>
                <div css={label}>メールアドレス</div>
              </div>
            </div>
                <div css={text}>info@kokuko-inc.com</div>
          </div>
        </div>
      </div>
    </BasicCard>
  )
};

export default CompanyCard;
