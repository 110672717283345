/** @jsxImportSource @emotion/react */
import type { VFC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../../../constants/colors";
import BasicIcon from "../icon/basicIcon";

const wrapper = css`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: 10px;
  @media (max-width: 540px) {
    margin-right: 0px;
  }
`

const text = css`
  font-size: 24px;
  width: 411px;
  color: ${COLORS.PRIMARY};
  @media (max-width: 540px) {
    font-size: 14px;
    font-weight: bold;
    margin-top: 2px;
    width: 251px;
  }
`

const line = css`
  width: 391px;
  height: 4px;
  background: linear-gradient(90deg, ${COLORS.PRIMARY}, ${COLORS.WHITE});
  position: absolute;
  left: 70px;
  bottom: 0px;
  @media (max-width: 540px) {
    width: 220px;
    height: 3px;
    left: 38px;
  }
`

const HeaderText: VFC = () => {
  return (
    <div css={wrapper}>
      <BasicIcon />
      <div css={text}>
        【個別指導】勉強の家庭教師 GrowUP
      </div>
      <div css={line}/>
    </div>
  )
}

export default HeaderText;