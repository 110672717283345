/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import type { VFC } from "react";
import { COLORS } from "../../../constants/colors";
import CircleText from "../text/circleText";

const wrapper = css`
  width: 105%;
  border-radius: 44px;
  background: #FFFAF2;
  padding: 15px 20px 25px;
  margin-bottom: 32px;
  @media (max-width: 540px) {
    width: 100%;
    padding: 5px 5px 15px;
    border-radius: 18px;
    margin-bottom: 12px;
  }
`;

const content = css`
  display: flex;
`;

const name = css`
  font-size: 26px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 540px) {
    margin-bottom: 8px;
    font-size: 10px;
  }
`;

const imageWrapper = css`
  width: 360px;
  height: 473px;
  position: relative;
  object-fit: cover;
  @media (max-width: 540px) {
    width: 128px;
    height: 170px;
  }
`;

const leftContent = css`
  @media (max-width: 540px) {
    width: 128px;
  }
`;

const svgWrapper = css`
  width: 150px;
  height: 93px;
  position: relative;
  margin-top: 17px;
  @media (max-width: 540px) {
    width: 50px;
    height: 33px;
    margin-top: 7px;
  }
`;

const pointWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 19px;
  @media (max-width: 540px) {
    flex: 1;
    margin-left: 5px;
  }
`;

const upPoint = css`
  display: flex;
  margin-bottom: 15px;
`;

const downPoint = css`
  display: flex;
`;

const total = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-top: 4px;
  @media (max-width: 540px) {
    font-size: 10px;
    margin-left: 0px;
    transform:scale(0.8);
  }
  span {
    color: ${COLORS.PRIMARY};
    font-size: 34px;
    @media (max-width: 540px) {
      font-size: 16px;
    }
  }
`;

const note = css`
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 11px;
  margin-top: 6px;
  @media (max-width: 540px) {
    display: none;
  }
`;

type Props = {
  grades: {
    name: string,
    image: string,
    result: {subject: string, rank: string}[],
    text: string,
    totalRank: string,
  }
}
const ResultCard: VFC<Props> = ({grades}) => {

  return (
    <div css={wrapper} >
      <div css={name}>{grades.name}</div>
      <div css={content}>
        <div css={leftContent}>
          <img css={imageWrapper} src={`${grades.image}`} alt='arrow' loading={"lazy"}/>
          <div css={note}>※個人情報のため、生徒様のお名前は伏せさせていただいております。</div>
        </div>
        <div css={pointWrapper}>
          <div css={upPoint}>
            <CircleText title={`${grades.result[0].subject}`} number={`${grades.result[0].rank}`} />
            <CircleText title={`${grades.result[1].subject}`} number={`${grades.result[1].rank}`} />
          </div>
          <div css={downPoint}>
            <CircleText title={`${grades.result[2].subject}`} number={`${grades.result[2].rank}`} />
            <CircleText title={`${grades.result[3].subject}`} number={`${grades.result[3].rank}`} />
            <CircleText title={`${grades.result[4].subject}`} number={`${grades.result[4].rank}`} />
          </div>
          <img css={svgWrapper} src="/svg/downArrow.svg" alt='arrow' loading={"lazy"}/>
          <div css={total}>{grades.text}<span>{grades.totalRank}</span></div>
        </div>
      </div>
    </div>
  )
};

export default ResultCard;