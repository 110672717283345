/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import type { ButtonHTMLAttributes, FC } from "react";
import { COLORS } from "../../../constants/colors";

type colorType = "black";

type Props = {
  colorType?: colorType;
  width?: string;
  type?: "submit" | "button";
  text?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const button = () => css`
    position: relative;
    width: 370px;
    height: 50px;
    border-radius: 12px;
    border: none;
    margin: 5px;
    background-color: ${COLORS.PRIMARY};
    &:hover {
      opacity: 0.6;
    }
`

const span = css`
  font-size: 25px;
  color: white;
  letter-spacing: 1px;
  font-weight: 100;
  margin-right: 20px;
`;

const triangle = css`
  border-right: 12px solid transparent;
  border-bottom: 15px solid ${COLORS.WHITE};
  border-left: 12px solid transparent; 
  transform: rotate(90deg);
  width: 0px;
  position: absolute;
  top: 17px;
  right: 13px;
`;

export const BasicButton: FC<Props> = ({ type, onClick, text }) => (
  <button type={type} css={button} onClick={onClick}>
    <span css={span}>{text}</span>
    <div css={triangle}></div>
  </button>
);

export default BasicButton;